<template>
  <div>
    <Header/>
    <main>
        <div class="bg-headline bg-no-repeat bg-cover bg-center relative">
    <div class="bg-gradient-to-tr from-black-95 via-black-75 to-black-95">
      <div class="2xl:container mx-auto px-4 py-14 lg:pl-28">
        <h1 class="font-serif font-black text-4xl sm:text-5xl text-white">Reset Password</h1>
      </div>
    </div>
  </div>

  <div v-if="showToast" class="p-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 notification" role="alert">
    <template v-if="errors">
      <ul>
        <template v-for="(fieldErrors, field) in errors">
          <li v-for="error in fieldErrors" :key="error">{{ field }}: {{ error }}</li>
        </template>
      </ul>
    </template>
    <template v-if="message !== ''">
      <p>{{message}}</p>
    </template>
  </div>


  <div class="2xl:container mx-auto px-2 py-14 lg:py-28">
    <form class="max-w-xs mx-auto" @submit.prevent="resetPassword">
        <input type="hidden" v-model="formData.token" />
            <input type="hidden" v-model="formData.email" />
        <div class="mb-6 flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-4" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M18 8h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h2V7a6 6 0 1 1 12 0v1zM5 10v10h14V10H5zm6 4h2v2h-2v-2zm-4 0h2v2H7v-2zm8 0h2v2h-2v-2zm1-6V7a4 4 0 1 0-8 0v1h8z"></path>
        </svg>
        <input class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full placeholder-green" v-model="formData.password" id="password" type="password" name="password" placeholder="New Password" spellcheck="false" autocomplete="new-password">
      </div>
      <div class="mb-6 flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-4" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M18 8h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h2V7a6 6 0 1 1 12 0v1zM5 10v10h14V10H5zm6 4h2v2h-2v-2zm-4 0h2v2H7v-2zm8 0h2v2h-2v-2zm1-6V7a4 4 0 1 0-8 0v1h8z"></path>
        </svg>
        <input class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full placeholder-green" v-model="formData.password_confirmation" id="password_confirmation" type="password" name="password" placeholder="Confirm Password" spellcheck="false" autocomplete="new-password">
      </div>
      <div class="flex items-center">
        <button 
            :disabled="isSubmitting" 
            class="ml-auto bg-black text-white hover:bg-black-dark rounded py-1.5 px-5" 
            type="submit">
            <svg v-if="isSubmitting"
            class="loader animate-spin text-yellow on" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M12 2a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1zm0 15a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1zm8.66-10a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5A1 1 0 0 1 20.66 7zM7.67 14.5a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5a1 1 0 0 1 1.366.366zM20.66 17a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 0 1 1-1.732l2.598 1.5A1 1 0 0 1 20.66 17zM7.67 9.5a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 1 1 1-1.732l2.598 1.5A1 1 0 0 1 7.67 9.5z"></path></svg>
            <div class="inline-flex items-center justify-center" v-if="!isSubmitting"> 
              Continue 
              <svg xmlns="http://www.w3.org/2000/svg" class="text-yellow ml-1" viewBox="0 0 24 24" width="22" height="22">
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path fill="currentColor" d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"></path>
              </svg>
            </div>
          </button>
      </div>
      <p class="text-center mt-6 text-xs text-gray"> By clicking Sign Up, you are indicating that you have read and acknowledge our <router-link class="text-green" to="/terms">Terms of Service</router-link>. </p>
      
    </form>
  </div>

    </main>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import axios from 'axios'
export default {
  name: 'ChainvaultmineResetPassword',
  components: {
    Header, Footer
  },
  data() {
    return {
        formData: {
            token: this.$route.params.token,
            email: this.$route.params.email,
            password: '',
            password_confirmation: ''
      },
      isSubmitting: false,
      message: '',
      showToast: false,
      showError: false,
      errors: {},
    };
  },

  mounted() {
   // console.log('mounted');
  },

  methods: {
    resetPassword() {
        if (this.isSubmitting) return;

        // Inicia la animación del botón y deshabilítalo
        this.isSubmitting = true;
        this.errors = {};

        if(this.formData.password !== this.formData.password_confirmation){
            this.message = 'Passwords do not match.'
            this.showToast = true;
            this.isSubmitting = false;
            setTimeout(()=>{
              this.showToast = false;
              this.message = '';
            },5000);
            return;
      }

      axios.post(process.env.VUE_APP_API_URL+'password/reset', this.formData)
        .then(response => {
            this.message = response.data.success;
            this.showToast = true;
            this.formData.password = '';
            this.formData.password_confirmation = '';

            setTimeout(()=>{
              this.showToast = false;
              this.message = '';
              this.$router.push('/login');
            },5000);
            this.isSubmitting = false;
        })
        .catch(error => {
            this.isSubmitting = false;
            this.showToast = true;
          if (error.response && error.response.data && error.response.data.email) {
            this.message = error.response.data.email[0];
            setTimeout(()=>{
              this.showToast = false;
              this.message = '';
            },5000);
          } else {
            this.message = error.response.data.error || 'An error occurred. Please try again.';
            setTimeout(()=>{
              this.showToast = false;
              this.message = '';
            },5000);
          }
          
        });
    },
  },
};
</script>