<template>
  <div>
    <Header/>
<main>
  <div class="relative bg-center bg-no-repeat bg-cover bg-headline"><div class="bg-gradient-to-tr from-black-95 via-black-75 to-black-95"><div class="px-4 mx-auto 2xl:container py-14 lg:pl-28"><h1 class="font-serif text-4xl font-black text-white sm:text-5xl">About</h1></div></div></div>


  <div class="px-2 mx-auto 2xl:container pt-14 lg:pt-28">
  <div class="mb-6 text-center">
    <img class="inline-block" src="@/assets/images/web-logo.svg" width="280" height="auto" alt="Logo">
  </div>
  <div class="max-w-screen-md p-6 mx-auto bg-white shadow-xl rounded-2xl md:p-10">
    <p class="text-xl italic font-light text-center text-black"> “Bitcoin is not only a currency of the future, but the revolution of traditional currencies and banking systems.” </p>
    <img class="w-20 h-20 rounded-full mx-auto my-3 border-2 border-[#08af80] brightness-110" src="@/assets/images/bnelson.jpg" alt="CEO Billy Nelson">
    <div class="text-sm font-medium text-center text-green-dark">— Billy Nelson, CEO of ChainMine</div>
  </div>
  <div class="grid items-center max-w-screen-lg grid-cols-4 gap-4 mx-auto mt-8 text-center">
    <div>
      <img class="inline-block mx-auto" src="@/assets/images/ssl.png" width="auto" height="56" alt="SSL Certified">
      <div class="mt-4 text-xs text-gray">SSL Certified Platform</div>
    </div>
    <div>
      <img class="inline-block mx-auto" src="@/assets/images/ukgov.png" width="100" height="auto" alt="UK Government">
      <div class="mt-4 text-xs text-gray">UK Approved Company</div>
    </div>
    <div>
      <img class="inline-block mx-auto" src="@/assets/images/bca.png" width="100" height="auto" alt="Blockchain Association">
      <div class="mt-4 text-xs text-gray">Member of the <br>Blockchain Association </div>
    </div>
    <div>
      <img class="inline-block mx-auto" src="@/assets/images/tfc.png" width="100" height="100" alt="The Financial Commission">
      <div class="mt-4 text-xs text-gray">Associated with the Financial Commission</div>
    </div>
  </div>
</div>


<div class="px-2 mx-auto 2xl:container pt-14 lg:pt-28">
  <div class="text-lg font-medium leading-none text-center text-black">Be part of the revolution</div>
  <h2 class="mb-8 font-serif text-4xl font-black leading-none text-center text-black">Our vision of true <span class="text-green">computing power</span>
  </h2>
  <div class="grid grid-cols-1 gap-12 leading-relaxed text-justify md:grid-cols-2 xl:gap-24 text-gray">
    <p>Unlike paper money, Bitcoin and other cryptocurrencies are generated mathematically. Our cloud miners provide the computing power needed to generate new Bitcoins and secure the Bitcoin network. Bitcoins are not managed by any central government or banks that decide the future of the system. The Bitcoin system offers freedom. Freedom that we want to make available and easily accessible to everyone. Today, Bitcoin is the most secure computer network on the planet, almost impenetrable! The reason for this is hash power.</p>
    <p>Hash power is used as the computing power that miners offer to the Bitcoin network, which requires specialized high-performance computers that would be an expensive purchase for individuals. To solve this problem, ChainMine offers the required hardware as a service. For anyone around the world. Our miners work in huge clusters and are already actively mining Bitcoins before you even sign up. This gives us an efficient way to use needed power and provide you with real hardware power at no additional cost.</p>
  </div>
</div>

<div class="grid items-center grid-cols-1 gap-10 px-2 mx-auto 2xl:container py-14 lg:py-28 xl:grid-cols-2">
  <div>
    <iframe loading="lazy" width="576" height="324" class="block w-full max-w-xl mx-auto shadow-xl rounded-2xl" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.38211020498!2d-0.16329118372560686!3d51.5245510172693!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ac8865cbc91%3A0x7b28e0053016bcfe!2s185%20Gloucester%20Pl%2C%20London%20NW1%206BU%2C%20UK!5e0!3m2!1sen!2sde!4v1636111685800!5m2!1sen!2sde" frameborder="0" style="border:0;" allowfullscreen="" data-ruffle-polyfilled=""></iframe>
  </div>
  <div class="max-w-xl mx-auto xl:mx-0 xl:max-w-none">
    <div class="text-lg font-medium leading-none text-black">Need help or have questions?</div>
    <h2 class="mt-1 mb-4 font-serif text-4xl font-black leading-none text-black">Get <span class="text-green">in touch</span> with us </h2>
    <p class="leading-relaxed text-gray">
      <strong>Support email address:</strong>
      <br> support@chainmine.cloud <br>
      <!-- <strong>Telegram Support Chat:</strong>
      <br> <a href="https://t.me/chainminexyz" target="_blank">
        @chainminexyz
      </a> <br>
      <strong>Facebook Page</strong>
      <br>
      <a href="https://www.facebook.com/ChainMineXyz" target="_blank">
        https://www.facebook.com/ChainMineXyz
      </a> 
      <br>
      <strong></strong> -->
      <strong>Mailing address:</strong>
      <br> ChainMine, 185 Gloucester Place, London England, NW1 6BU
    </p>
    <router-link to="/faq" class="inline-flex items-center justify-center px-6 py-2 mt-6 mr-4 text-lg text-white bg-black rounded shadow hover:bg-black-dark">
      <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 text-yellow" viewBox="0 0 24 24" width="24" height="24">
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path fill="currentColor" d="M5.763 17H20V5H4v13.385L5.763 17zm.692 2L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455zM11 14h2v2h-2v-2zM8.567 8.813A3.501 3.501 0 1 1 12 13h-1v-2h1a1.5 1.5 0 1 0-1.471-1.794l-1.962-.393z"></path>
      </svg> FAQs </router-link>
    <a href="https://chainmine.cloud/uk_gov_102904820.pdf" class="inline-flex items-center justify-center px-6 py-2 mt-6 text-lg text-black rounded shadow bg-green hover:bg-green-dark">
      <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" viewBox="0 0 24 24" width="24" height="24">
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path fill="currentColor" d="M20 2a3 3 0 0 1 3 3v2h-2v12a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3v-2h16v2a1 1 0 0 0 .883.993L18 20a1 1 0 0 0 .993-.883L19 19V4H6a1 1 0 0 0-.993.883L5 5v10H3V5a3 3 0 0 1 3-3h14z"></path>
      </svg> Download License </a>
  </div>
</div>


</main>
    
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'ChainvaultmineAboutView',
  components: {
    Header, Footer
  },
  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>