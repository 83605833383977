<template>
  <div>
    <Header/>
  
  <div class="flex flex-row w-full account-menu">
    <Sidebar/>

    <div 
  v-if="showMessage"
  class="p-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 notification" role="alert">
    {{message}}
  </div>

    <div class="flex-1 overflow-y-auto">
  <div class="px-4 py-4 mx-auto xl:container md:py-14 md:px-8">
    <h1 class="pb-4 mb-8 text-3xl font-bold leading-none text-black border-b-2 border-gray-light max-md:pl-12">Dashboard</h1>
    <div class="grid grid-cols-1 gap-4 px-8 py-8 mb-12 bg-white shadow-xl rounded-2xl md:px-10 md:py-10 lg:grid-cols-3">
      <div class="flex items-center ">
        <div class="flex-none">
          <svg xmlns="http://www.w3.org/2000/svg" class="text-green" viewBox="0 0 24 24" width="56" height="56">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-4.987-3.744A7.966 7.966 0 0 0 12 20c1.97 0 3.773-.712 5.167-1.892A6.979 6.979 0 0 0 12.16 16a6.981 6.981 0 0 0-5.147 2.256zM5.616 16.82A8.975 8.975 0 0 1 12.16 14a8.972 8.972 0 0 1 6.362 2.634 8 8 0 1 0-12.906.187zM12 13a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"></path>
          </svg>
        </div>
        <div class="ml-3">
          <span class="font-medium leading-none text-black">Hello</span>
          <h2 class="mb-2 text-xl font-medium leading-none text-black">{{ user?.user?.username || 'Guest' }}</h2>
        </div>
      </div>
      <div class="flex flex-col items-center md:flex-row md:col-span-2">
        <img src="@/assets/images/m30s.jpg" width="110" height="auto" alt="Whatsminer 30S">
        <div class="ml-3">
          <span class="font-medium leading-none text-black">Total Device Power</span>
          <h2 class="mb-2 text-xl font-medium leading-none text-black livepower">{{userPower}} </h2>
          <div class="grid grid-cols-2 gap-4 my-0.5 items-center">
            <div class="text-xs uppercase text-gray">Purchased:</div>
            <div class="text-xs text-right unitprice text-green-dark">{{purchasedPower}} {{convertPower(purchasedPower)}}</div>
          </div>
          <div class="grid items-center grid-cols-2 gap-4">
            <div class="text-xs uppercase text-gray">Ref Commission:</div>
            <div class="text-xs text-right unitprice text-green-dark">{{commissionPower}} {{convertPower(commissionPower)}}</div>
          </div>
        </div>
      </div>
    </div>
    <h2 class="mb-6 text-2xl font-bold leading-none text-black">Your Bitcoin Minings Overview</h2>
    <div class="grid grid-cols-1 gap-4 mb-8 lg:grid-cols-4">
      <div class="mb-8 2xl:pl-8">
        <div class="flex items-center py-4 pl-4 bg-black rounded shadow-md sm:py-6">
          <svg xmlns="http://www.w3.org/2000/svg" class="flex-none mr-3 text-green" viewBox="0 0 24 24" width="32" height="32">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-4H8V8h3V6h2v2h1a2.5 2.5 0 0 1 2 4 2.5 2.5 0 0 1-2 4h-1v2h-2v-2zm-1-3v1h4a.5.5 0 1 0 0-1h-4zm0-3v1h4a.5.5 0 1 0 0-1h-4z"></path>
          </svg>
          <div>
            <h2 class="mb-1 text-sm font-medium leading-none text-green"> Total Mined </h2>
            <div class="text-base font-semibold leading-none text-white sm:text-xl livebalance"> {{convertToSelectedCurrency(balance)}} {{selectedCurrency}} </div>
          </div>
        </div>
        <div class="flex items-center py-4 pl-4 border-b-2 sm:py-6 border-gray-light">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex-none mr-3 text-green" width="32" height="32">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>

          <div>
            <h2 class="mb-1 text-sm font-semibold leading-none text-black">Hourly Profit</h2>
            <div class="text-base font-semibold leading-none sm:text-xl text-green-dark convertbtc"> {{convertToSelectedCurrency(hourlyEarning)}} {{selectedCurrency}} </div>
          </div>
        </div>
        <div class="flex items-center py-4 pl-4 border-b-2 sm:py-6 border-gray-light">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#0ad69c" height="32" width="32" version="1.1" viewBox="0 0 489 489" enable-background="new 0 0 489 489" class="flex-none mr-3 text-green">
              <g>
                <g>
                  <path d="m160.2,254.95l-57.2,57.2v27h129v-30.2h-85.3l43.7-43.7c13.5-13.5 22.9-25 28.1-34.3 5.2-8.3 7.3-17.7 7.3-28.1 0-17.7-6.2-31.2-17.7-40.6-11.4-10.4-26-14.6-41.6-14.6s-28.1,3.1-38.5,9.4c-10.4,6.2-18.7,15.6-27,28.1l26,14.6c10.4-15.6 22.9-23.9 38.5-23.9 8.3,0 15.6,3.1 21.8,8.3 6.2,5.2 8.3,11.4 8.3,18.7s-3.1,14.6-8.3,21.8c-5.2,7.4-14.6,17.8-27.1,30.3z"/>
                  <polygon points="366.2,341.35 366.2,293.45 389.1,293.45 389.1,264.35 366.2,264.35 366.2,229.95 336,229.95 336,264.35     291.3,264.35 358.9,153.05 324.6,153.05 254.9,265.35 254.9,293.45 336,293.45 336,341.35   "/>
                  <path d="M245.5,20.85c55.7,0,109.4,20.5,150.9,58.4l-53.1-4.3l-1,20.8l88.4,7.3l-5.2-91.5l-20.8,1l2.3,49.5    c-45.2-40.4-101.9-62-162.5-62C109.3,0.05,0,109.35,0,244.55h20.8C20.8,120.75,120.7,20.85,245.5,20.85z"/>
                  <path d="m468.2,244.45v0.1c0,123.8-99.9,223.7-223.7,223.7-56,0-109.6-21.1-150.8-58.3l52,4.2 1-20.8-88.4-7.3 5.2,91.5 20.8-1-2.3-49.3c44.9,39.8 102.9,61.7 162.5,61.7 135.2,0 244.5-109.2 244.5-244.5l-20.8,0z"/>
                </g>
              </g>
            </svg>
          <div>
            <h2 class="mb-1 text-sm font-semibold leading-none text-black">Daily Profit</h2>
            <div class="text-base font-semibold leading-none sm:text-xl text-green-dark convertbtc"> {{convertToSelectedCurrency(dailyEarning)}} {{selectedCurrency}} </div>
          </div>
        </div>
        <div class="flex items-center py-4 pl-4 border-b-2 sm:py-6 border-gray-light">
          <svg xmlns="http://www.w3.org/2000/svg" class="flex-none mr-3 text-green" viewBox="0 0 24 24" width="32" height="32">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm3 6V5h-3v2h-2V5H9v2H7V5H4v4h16zm0 2H4v8h16v-8zM6 13h5v4H6v-4z"></path>
          </svg>
          <div>
            <h2 class="mb-1 text-sm font-semibold leading-none text-black">Weekly Profit</h2>
            <div class="text-base font-semibold leading-none sm:text-xl text-green-dark convertbtc"> {{convertToSelectedCurrency(weeklyEarning)}} {{selectedCurrency}} </div>
          </div>
        </div>
        <div class="flex items-center py-4 pl-4 border-b-2 sm:py-6 border-gray-light">
          <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 24 24" class="flex-none mr-3 text-green">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Zm3-7h.01v.01H8V13Zm4 0h.01v.01H12V13Zm4 0h.01v.01H16V13Zm-8 4h.01v.01H8V17Zm4 0h.01v.01H12V17Zm4 0h.01v.01H16V17Z"/>
          </svg>

          <div>
            <h2 class="mb-1 text-sm font-semibold leading-none text-black">Monthly Profit</h2>
            <div class="text-base font-semibold leading-none sm:text-xl text-green-dark convertbtc"> {{convertToSelectedCurrency(monthlyEarning)}} {{selectedCurrency}} </div>
          </div>
        </div>
      </div>
      

    <div class="lg:col-span-3 lg:pl-8">
  <!-- Referral Link Section -->
  <h2 class="mb-3 text-xl font-bold text-black">Referral link</h2>
  <div class="flex items-center max-w-sm mb-4">
    <svg xmlns="http://www.w3.org/2000/svg" @click="copyRef" class="text-green mr-1.5 pointer" viewBox="0 0 24 24" width="22" height="22">
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path fill="currentColor" d="M18.364 15.536L16.95 14.12l1.414-1.414a5 5 0 1 0-7.071-7.071L9.879 7.05 8.464 5.636 9.88 4.222a7 7 0 0 1 9.9 9.9l-1.415 1.414zm-2.828 2.828l-1.415 1.414a7 7 0 0 1-9.9-9.9l1.415-1.414L7.05 9.88l-1.414 1.414a5 5 0 1 0 7.071 7.071l1.414-1.414 1.415 1.414zm-.708-10.607l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07z"></path>
    </svg>
    <input class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full max-w-xs placeholder-green" v-on:focus="$event.target.select()" ref="myinput" :value="'https://chainmine.cloud/?ref='+ user?.user?.referral_code || 'Guest'" type="text" name="referral_link" placeholder="Referral link" spellcheck="false" readonly>
  </div>
  <div class="flex items-center max-w-sm mr-3">
    <router-link to="/referral" class="shadow text-lg bg-black text-white hover:bg-black-dark rounded py-2 px-6 inline-flex items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-3 text-yellow" width="24" height="24">
        <path stroke-linecap="round" stroke-linejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
      </svg>
      Partnering
    </router-link>
  </div>

  <!-- Bitcoin Wallet Section -->
  <h2 class="mb-3 text-xl font-bold text-black mt-5">Bitcoin Wallet</h2>
  <div class="flex items-center max-w-sm mb-4">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-green mr-1.5" width="22" height="22">
      <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 3.75 9.375v-4.5ZM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 0 1-1.125-1.125v-4.5ZM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 13.5 9.375v-4.5Z" />
      <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75ZM6.75 16.5h.75v.75h-.75v-.75ZM16.5 6.75h.75v.75h-.75v-.75ZM13.5 13.5h.75v.75h-.75v-.75ZM13.5 19.5h.75v.75h-.75v-.75ZM19.5 13.5h.75v.75h-.75v-.75ZM19.5 19.5h.75v.75h-.75v-.75ZM16.5 16.5h.75v.75h-.75v-.75Z" />
    </svg>
    <template v-if="!wallet">
      <input class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full max-w-xs placeholder-green" type="text" name="bitcoin_wallet" placeholder="Add Bitcoin address in settings" spellcheck="false" readonly>
    </template>
    <template v-else>
      <input class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full max-w-xs placeholder-green" :value="wallet" type="text" name="bitcoin_wallet" placeholder="Add Bitcoin address in settings" spellcheck="false" readonly>
    </template>
  </div>
  <div class="flex items-center max-w-sm mr-3">
    <router-link to="/settings" class="shadow text-lg bg-black text-white hover:bg-black-dark rounded py-2 px-6 inline-flex items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" class="mr-3 text-yellow" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M3.34 17a10.018 10.018 0 0 1-.978-2.326 3 3 0 0 0 .002-5.347A9.99 9.99 0 0 1 4.865 4.99a3 3 0 0 0 4.631-2.674 9.99 9.99 0 0 1 5.007.002 3 3 0 0 0 4.632 2.672c.579.59 1.093 1.261 1.525 2.01.433.749.757 1.53.978 2.326a3 3 0 0 0-.002 5.347 9.99 9.99 0 0 1-2.501 4.337 3 3 0 0 0-4.631 2.674 9.99 9.99 0 0 1-5.007-.002 3 3 0 0 0-4.632-2.672A10.018 10.018 0 0 1 3.34 17zm5.66.196a4.993 4.993 0 0 1 2.25 2.77c.499.047 1 .048 1.499.001A4.993 4.993 0 0 1 15 17.197a4.993 4.993 0 0 1 3.525-.565c.29-.408.54-.843.748-1.298A4.993 4.993 0 0 1 18 12c0-1.26.47-2.437 1.273-3.334a8.126 8.126 0 0 0-.75-1.298A4.993 4.993 0 0 1 15 6.804a4.993 4.993 0 0 1-2.25-2.77c-.499-.047-1-.048-1.499-.001A4.993 4.993 0 0 1 9 6.803a4.993 4.993 0 0 1-3.525.565 7.99 7.99 0 0 0-.748 1.298A4.993 4.993 0 0 1 6 12c0 1.26-.47 2.437-1.273 3.334a8.126 8.126 0 0 0 .75 1.298A4.993 4.993 0 0 1 9 17.196zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path>
        </svg>
      Change
    </router-link>
  </div>
</div>
</div>



    <div class="grid grid-cols-1 gap-4 mb-8 lg:grid-cols-4">
      <div class="mb-8 2xl:pl-8"></div>
    </div>
    <h2 class="mb-8 text-2xl font-bold leading-none text-black">Calculate future minings</h2>
    <div id="calculator" class="rounded-2xl bg-white shadow-xl p-6 md:p-9 max-w-md lg:max-w-screen-lg grid grid-cols-1 lg:grid-cols-6 lg:bg-gift bg-right bg-cover sm:bg-contain bg-no-repeat">
      <div class="lg:col-span-2 lg:pr-6">
        <div class="mb-2 font-medium text-center text-black">Amount you want to pay:</div>
        <div class="flex items-center mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" class="flex-none mr-4 text-green" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-4H8V8h3V6h2v2h1a2.5 2.5 0 0 1 2 4 2.5 2.5 0 0 1-2 4h-1v2h-2v-2zm-1-3v1h4a.5.5 0 1 0 0-1h-4zm0-3v1h4a.5.5 0 1 0 0-1h-4z"></path>
          </svg>
          <input 
          @input="updateCalculator"
          @keypress="restrictToNumbers"
          class="text-black block border border-gray-light bg-white-dark rounded px-3 py-1.5 w-full placeholder-green price" type="number" v-model="inputAmount" id="btc" autocomplete="off">
          <select id="currency" class="flex-none w-20 px-3 py-2 text-sm bg-white rounded-r-sm cursor-pointer setcurrency hover:bg-white-dark focus:bg-white-dark text-green-dark">
              <option value="USD" selected>USD</option>
          </select>
        </div>
        <div class="grid items-center grid-cols-2 gap-4">
          <div class="text-sm font-medium text-black uppercase"> Miner Hashrate: </div>
          <div class="text-lg font-semibold text-right power text-green-dark">{{minerHashRate}} {{hashUnit}}</div>
        </div>
        <div class="grid items-center grid-cols-2 gap-4 pb-4 mb-4 border-b border-gray-light">
          <div class="text-xs uppercase text-gray"> Unit Price: </div>
          <div class="text-xs text-right unitprice text-green-dark">{{unitPrice}} USD</div>
        </div>
        <div class="grid grid-cols-2 gap-4 items-center mb-0.5">
          <div class="text-sm font-medium uppercase text-gray"> Minimum: </div>
          <div class="font-semibold text-right text-green">{{minimumHash.toFixed(2)}} TH/s</div>
        </div>
        <div class="grid items-center grid-cols-2 gap-4">
          <div class="text-sm font-medium uppercase text-gray"> Maximum: </div>
          <div class="font-semibold text-right text-green">{{maximumHash.toFixed(2)}} PH/s</div>
        </div>
        
      </div>
      <div class="lg:col-span-3 lg:pl-6 lg:border-l lg:border-gray-light">
        <div class="mb-6 font-medium text-black">Estimated Income by Hashrate</div>
        <div class="grid items-center grid-cols-3 gap-2 mb-1">
          <div class="text-sm font-medium text-black uppercase"> per Hour: </div>
          <div class="text-green hourlypc">{{ (hourlyIncome * 100).toFixed(2) }} %</div>
          <div class="text-sm font-semibold lg:text-base text-green-dark hourly">{{ hourlyIncomeValue }} USD</div>
        </div>
        <div class="grid items-center grid-cols-3 gap-2 mb-1">
          <div class="text-sm font-medium text-black uppercase"> per Day: </div>
          <div class="text-green dailypc">{{ (dailyIncome * 100).toFixed(2) }} %</div>
          <div class="text-sm font-semibold lg:text-base text-green-dark daily">{{ dailyIncomeValue }} USD</div>
        </div>
        <div class="grid items-center grid-cols-3 gap-2 mb-1">
          <div class="text-sm font-medium text-black uppercase"> per Week: </div>
          <div class="text-green weeklypc">{{ (weeklyIncome * 100).toFixed(2) }} %</div>
          <div class="text-sm font-semibold lg:text-base text-green-dark weekly">{{ weeklyIncomeValue }} USD</div>
        </div>
        <div class="grid items-center grid-cols-3 gap-2 mb-1">
          <div class="text-sm font-medium text-black uppercase"> per Month: </div>
          <div class="text-green monthlypc">{{ (monthlyIncome * 100).toFixed(2) }} %</div>
          <div class="text-sm font-semibold lg:text-base text-green-dark monthly">{{ monthlyIncomeValue }} USD</div>
        </div>
        <div class="grid items-center grid-cols-3 gap-2">
          <div class="text-sm font-medium uppercase text-gray"> estimated ROI: </div>
          <div></div>
          <div class="font-semibold text-green">~ <span class="roi">{{ roi === '365' ? '∞' : roi +' '+ 'days' }}</span>
          </div>
        </div>
      </div>
    </div>
    <p class="text-gray italic text-sm mt-6 max-w-xl"> Don't limit yourself to fixed price plans. You decide how much hardware power you want to buy! No maintenance or waiting periods. No hidden setup or transfer fees. No term limits. Your cloud mining hardware starts automatically and is always online to mine Bitcoins for you.</p>
  </div>
</div>



  </div>
  
</div>
</template>

<script>
import Header from '@/components/Header.vue'
import Sidebar from '@/views/user/Sidebar.vue'
import axios from 'axios'
import { mapState, mapActions, mapGetters } from 'vuex';// eslint-disable-line
export default {
  name: 'ChainvaultmineDashboardView',
  components: {
    Header, Sidebar
  },
  data() {
    return {
      showMessage: false,
      message: '',
      hashUnit: 'TH/s',
      inputAmount: 18.78,
      unitPrice: 4.58,
      minimumHash: 4.10,
      maximumHash: 46.90,
      roi: 0,
      minerHashRate: 0,
      hourlyIncome: 0,
      hourlyIncomeValue: 0,
      dailyIncome: 0,
      dailyIncomeValue: 0,
      weeklyIncome: 0,
      weeklyIncomeValue: 0,
      monthlyIncome: 0,
      monthlyIncomeValue: 0,
      userPower: 1,
      purchasedPower: 0,
      commissionPower: 0,
      monthlyEarning: 0,
      dailyEarning: 0,
      weeklyEarning: 0,
      hourlyEarning: 0,
    };
  },
  computed: {
      ...mapGetters({
          autenticado: 'user/autenticado',
          user: 'user/usuario',
          balance: 'user/balance',
          power: 'user/power',
          wallet: 'user/wallet'
      }),
      ...mapState(['selectedCurrency']),
      ...mapGetters(['convertToSelectedCurrency']),
  },
  mounted() {
    this.updateCalculator();
    this.fetchMiningPower()
    this.interval = setInterval(this.updatePower, 1000);
    this.$store.dispatch('user/fetchSaldo');
    window.Echo.channel('notifications')
        .listen('RealTimeNotification', (e) => {
          this.showMessage = true;
          this.message = e.message
          setTimeout(() => {
              this.showMessage = false
              this.message = ''
            }, 5000)
            // Aquí puedes mostrar la notificación en la interfaz de usuario
        });
    //this.convertPower(1)
  },
  beforeDestroy() {
      clearInterval(this.interval);
  },
  methods: {
    convertPower(amount) {
        const hashUnits = ['GH/s', 'TH/s', 'PH/s', 'EH/s']; // Unidades de hash
        let index = 1; // Índice de TH/s por defecto
        if(amount === 0){
          index = 0;
        }
        
        while (amount >= 1000 && index < hashUnits.length - 1) {
            amount /= 1000;
            index++;
        }
        //console.log(hashUnits[index])
        return hashUnits[index];
    },
    calculateEarnings() {
        // Calcular las ganancias diarias, semanales y por hora basadas en las ganancias mensuales
        const daily = this.monthlyEarning / 30;
        const weekly = daily * 7;
        const hourly = daily / 24;

        // Redondear los valores a 2 decimales
        this.dailyEarning = daily;
        this.weeklyEarning = weekly;
        this.hourlyEarning = hourly;
    },
    fetchMiningPower() {
      //this.isLoading = true;
      axios.get(process.env.VUE_APP_API_URL+'getMiningData')
      .then((res)=>{
        this.purchasedPower = res.data.purchased_power.toFixed(2);
        this.commissionPower = res.data.commission_power.toFixed(2);
        this.monthlyEarning = res.data.monthly;
        this.originalMonthlyEarning  = res.data.monthly;
        this.calculateEarnings();
        //this.calculateReward();
        //this.isLoading = false;
      })
      .catch((error)=>{
        console.log('error getting User Power',error.response.error);
        //this.isLoading = false;
      })
    },
    updatePower() {
        const random = Math.random() * (0.025 + 0.025) - 0.025;
        let userPower = this.power; // Este valor sería dinámico, pero por ahora lo dejamos fijo para el ejemplo
        
        let { hashValue, hashUnit } = this.convertToTHS(userPower);
        userPower = parseFloat((hashValue + random).toFixed(2));
        this.userPower = `${userPower} ${hashUnit}`;
        
    },
    restrictToNumbers(event) {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        // Permitir solo números y el punto decimal
        if (!/^[0-9.]+$/.test(keyValue)) {
            event.preventDefault();
        }

        // Asegurarse de que solo se permita un punto decimal
        if (keyValue === '.' && event.target.value.includes('.')) {
            event.preventDefault();
        }
    },
    convertHashRate(value, fromUnit, toUnit) {
        const units = {
            GH: 1,
            TH: 1000,
            PH: 1000000,
            EH: 1000000000
        };

        const fromValue = value * units[fromUnit];
        const toValue = fromValue / units[toUnit];

        return toValue;
    },
    convertToTHS(amount) {
        // Convertir a TH/s
        // Puedes ajustar esta función según las unidades que uses
        // GH/s, TH/s, PH/s, EH/s
        // Por ejemplo, si inputAmount es en PH/s, necesitas multiplicar por 1000 para obtener TH/s
        const hashUnits = ['GH/s', 'TH/s', 'PH/s', 'EH/s']; // Unidades de hash
        let index = 1; // Índice de TH/s por defecto
        
        while (amount >= 1000 && index < hashUnits.length - 1) {
            amount /= 1000;
            index++;
        }
        
        return {
            hashValue: amount,
            hashUnit: hashUnits[index]
        };
    },
    updateCalculator() {
        const inputAmount = this.inputAmount; // Valor por defecto
        const unitPrice = 4.58; // Valor por defecto
        
        let hashRate = 0;
        let monthly = 0;
        let daily = 0;
        let weekly = 0;
        let hourly = 0;
        let roi = 365;
        
        if(inputAmount > 0){
            //monthly = 2.22;
             if (inputAmount <= 30) {
                monthly = 1.22;
            } else if (inputAmount >= 31 && inputAmount <= 50) {
                monthly = 1.63;
            } else if (inputAmount >= 51 && inputAmount <= 120) {
                monthly = 1.83;
            } else if (inputAmount >= 121 && inputAmount <= 700) {
                monthly = 1.92;
            } else if (inputAmount >= 701 && inputAmount <= 1500) {
                monthly = 2.22;
            } else { 
                // En este caso, inputAmount es mayor que 1500, pero puedes establecer el valor que desees
                // Si deseas mantener el valor de monthly en 2.22 para valores mayores que 1500, puedes dejar esta parte sin cambios
                monthly = 2.22;
            }
            
            daily = monthly / 30;
            weekly = daily * 7;
            hourly = daily / 24;
            
            roi = 30 / monthly;
        }
        hashRate = (inputAmount / unitPrice).toFixed(2);
        //hashRate = (unitPrice * inputAmount).toFixed(2);
        
        

        // Convertir el valor ingresado a TH/s
        let { hashValue, hashUnit } = this.convertToTHS(hashRate);

        //console.log(hashValue);
        this.minerHashRate = hashValue;
        this.hashUnit = hashUnit; // Unidad de hash
        
        // Ajustar los valores de los beneficios
        this.hourlyIncome = hourly;
        this.hourlyIncomeValue = (hourly * inputAmount).toFixed(2);
        this.dailyIncome = daily.toFixed(2);
        this.dailyIncomeValue = (daily * inputAmount).toFixed(2);
        this.weeklyIncome = weekly.toFixed(2);
        this.weeklyIncomeValue = (weekly * inputAmount).toFixed(2);
        this.monthlyIncome = monthly.toFixed(2);
        this.monthlyIncomeValue = (monthly * inputAmount).toFixed(2);
        
        this.roi = roi % 1 === 0 ? roi.toFixed(0) : roi.toFixed(1);
        
        
    },
    profitPerTime(amount) {
        let monthly = amount < 100 ? 1.2 : 1.93;
        let daily = monthly / 30;
        let weekly = daily * 7;
        let hourly = daily / 60;

        return { hourly, daily, weekly, monthly };  
    },
    copyRef() {
        this.showMessage = true;
        this.message = 'Copied to Clipboard'
        this.$refs.myinput.focus();
        document.execCommand('copy');
        setTimeout(() => {
            this.showMessage = false
            this.message = ''
        }, 5000)
    },
  },
};
</script>
<style scoped>
.mt-5 {
  margin-top: 2rem
}

.pointer {
  cursor: pointer
}
</style>