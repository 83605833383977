<template>
  <div>
    <Header/>
  
  <div class="flex flex-row w-full account-menu">
    <Sidebar/>

    <div v-if="showToast" class="p-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 notification" role="alert">
      <template v-if="message !== ''">
        <p>{{message}}</p>
      </template>
    </div>
    <div class="flex-1 overflow-y-auto">
  <div class="px-4 py-4 mx-auto xl:container md:py-14 md:px-8">
    <h1 class="pb-4 mb-8 text-3xl font-bold leading-none text-black border-b-2 border-gray-light max-md:pl-12">Transfer BTC</h1>
    <h2 class="mb-6 text-2xl font-bold leading-none text-black">Payout overview</h2>
    <div class="grid grid-cols-1 gap-4 mb-8 lg:grid-cols-4">
      <div class="mb-8 2xl:pl-8">
        <div class="flex items-center py-4 pl-4 bg-black rounded shadow-md sm:py-6">
          <svg xmlns="http://www.w3.org/2000/svg" class="flex-none mr-3 text-green" viewBox="0 0 24 24" width="32" height="32">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-4H8V8h3V6h2v2h1a2.5 2.5 0 0 1 2 4 2.5 2.5 0 0 1-2 4h-1v2h-2v-2zm-1-3v1h4a.5.5 0 1 0 0-1h-4zm0-3v1h4a.5.5 0 1 0 0-1h-4z"></path>
          </svg>
          <div>
            <h2 class="mb-1 text-sm font-medium leading-none text-green">Total payout</h2>
            <div class="text-base font-semibold leading-none text-white sm:text-xl convertbtcs"> {{ convertToSelectedCurrency(withdrawsAmount) }} {{selectedCurrency}}</div>
          </div>
        </div>
        <div class="flex items-center py-4 pl-4 border-b-2 sm:py-6 border-gray-light">
          <svg xmlns="http://www.w3.org/2000/svg" class="flex-none mr-3 text-green" viewBox="0 0 24 24" width="32" height="32">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M11 4h10v2H11V4zm0 4h6v2h-6V8zm0 6h10v2H11v-2zm0 4h6v2h-6v-2zM3 4h6v6H3V4zm2 2v2h2V6H5zm-2 8h6v6H3v-6zm2 2v2h2v-2H5z"></path>
          </svg>
          <div>
            <h2 class="mb-1 text-sm font-medium leading-none text-black">Payouts</h2>
            <div class="text-base font-semibold leading-none sm:text-xl text-green-dark"> {{withdrawsCount}} </div>
          </div>
        </div>
      </div>
      <form class="lg:col-span-3 lg:pl-8" @submit.prevent="withdraw">
        <h2 class="mb-3 text-sm font-medium leading-none text-black ml-9">Withdrawal amount</h2>
        <div class="flex items-center max-w-sm mb-8">
          <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 text-green" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M17 16h2V4H9v2h8v10zm0 2v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3zM5.003 8L5 20h10V8H5.003zM7 16h4.5a.5.5 0 1 0 0-1h-3a2.5 2.5 0 1 1 0-5H9V9h2v1h2v2H8.5a.5.5 0 1 0 0 1h3a2.5 2.5 0 1 1 0 5H11v1H9v-1H7v-2z"></path>
          </svg>
          <div class="w-full">
            <div class="flex shadow-md">
              
                <!-- <input 
                    class="text-black block bg-white rounded-l px-3 py-1.5 w-full placeholder-green convertbtc" 
                    :class="{ 'border-red-500': inputAmount <= 0 || inputAmount < minimumWithdraw }"
                    type="number" 
                    name="payout_value" 
                    v-model="inputAmount" 
                    autocomplete="off"
                    placeholder="Enter amount to withdraw"
                > -->
                <input type="text" v-model="inputAmount" 
                @keypress="restrictToNumbersAndDecimal" 
                placeholder="Enter amount to withdraw"
                class="text-black block bg-white rounded-l px-3 py-1.5 w-full placeholder-green convertbtc" maxlength="">
              <select id="currency" class="setcurrency text-black block bg-white px-3 py-1.5 hover:bg-white-dark w-24 rounded-r cursor-pointer text-sm" title="Choose your currency">
                <option value="USD" selected>USD</option>
              </select>
            </div>
            <div class="grid grid-cols-2 mb-1 gap-4 items-center mt-4">
              <div class="uppercase text-xs text-gray">Available Balance:</div>
              <div class="text-right text-xs text-green-dark">{{convertToSelectedCurrency(balance)}} {{selectedCurrency}}</div>
            </div>
            <div class="grid grid-cols-2 mb-1 gap-4 items-center">
              <div class="uppercase text-xs text-gray">Minimum:</div>
              <div class="text-right text-xs text-green-dark">{{convertToSelectedCurrency(minimumWithdraw)}} {{selectedCurrency}}</div>
            </div>
          </div>
        </div>
        <h2 class="mb-3 text-sm font-medium leading-none text-black ml-9">Bitcoin receiver address </h2>
        <div class="flex items-center max-w-sm mb-8">
          <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 text-green" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
          </svg>
          <input 
            class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full placeholder-green" 
            type="text" 
            name="bitcoin_address" 
            :value="wallet" 
            readonly 
            placeholder="Add Bitcoin address in settings" 
            spellcheck="false" 
            autocomplete="off">
        </div>
        <div>
          <button 
          :disabled="isSubmitting"
          class="ml-9 submit bg-black text-white hover:bg-black-dark rounded py-1.5 px-5" 
          type="submit">
            <svg 
            v-if="isSubmitting"
            class="loader animate-spin text-yellow on" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22">
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path fill="currentColor" d="M12 2a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1zm0 15a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1zm8.66-10a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5A1 1 0 0 1 20.66 7zM7.67 14.5a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5a1 1 0 0 1 1.366.366zM20.66 17a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 0 1 1-1.732l2.598 1.5A1 1 0 0 1 20.66 17zM7.67 9.5a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 1 1 1-1.732l2.598 1.5A1 1 0 0 1 7.67 9.5z"></path>
            </svg>
            <div class="inline-flex items-center justify-center" 
            v-if="!isSubmitting"> Send <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 text-yellow" viewBox="0 0 24 24" width="22" height="22">
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path fill="currentColor" d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"></path>
              </svg>
            </div>
          </button>
        </div>
        <p class="max-w-xl mt-6 mb-6 text-sm leading-relaxed">Please make sure that the Bitcoin address you provide is correct. The payment process cannot be reversed.</p>
      </form>
    </div>
    <h2 class="mt-16 mb-8 text-2xl font-bold leading-none text-black">All your payouts</h2>
    <div class="w-full max-w-full overflow-x-auto shadow-lg">
      <table class="text-sm table-fixed w-248 lg:w-full text-gray">
        <tbody>
          <tr class="text-base text-left text-white">
            <th class="px-4 py-2 font-medium bg-black rounded-l">Transaction</th>
            <th class="px-4 py-2 font-medium bg-black">Status</th>
            <th class="px-4 py-2 font-medium bg-black">Amount</th>
            <th class="px-4 py-2 font-medium bg-black rounded-r">Date &amp; Time</th>
          </tr>
          <template v-if="withdrawData.data.length > 0">
            <tr class="bg-white hover:bg-white-dark" v-for="(withdraws, index) in withdrawData.data" :key="index">
                <td class="px-4 border-b border-gray-light py-3 truncate">
                  <template v-if="withdraws.processed === 0">
                    (pending)
                  </template>
                  <template v-else>
                    <a class="text-green" :href="'https://blockstream.info/tx/' + withdraws.transaction_id" target="_blank">
                      {{ withdraws.transaction_id }}
                    </a>
                  </template>
                </td>
                <td class="px-4 border-b border-gray-light py-3">{{ withdraws.processed === 0 ? 'In queue' : 'Done' }}</td>
                <td class="px-4 border-b border-gray-light py-3">{{ convertToSelectedCurrency(withdraws.usd) }} {{selectedCurrency}}</td>
                <td class="px-4 border-b border-gray-light py-3">{{ formatDate(withdraws.created_at) }}</td>
            </tr>
          </template>
          <template v-else>
            <tr class="bg-white">
              <td class="px-4 py-3 text-center border-b border-gray-light" colspan="4">(none)</td>
            </tr>
          </template>



        </tbody>
      </table>
    </div>

    <div class="mt-6 text-center">
      <vue-pagination  
      v-if="withdrawData.data.length > 0"
  :pagination="withdrawData"
  @paginate="getWithdraws"
  :offset="4">
</vue-pagination>
    </div>
  </div>
</div>

  </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Sidebar from '@/views/user/Sidebar.vue'
import { mapState, mapActions, mapGetters } from 'vuex';// eslint-disable-line
import axios from 'axios';// eslint-disable-line
import moment from 'moment';
import VuePagination from '@/components/Pagination.vue';
export default {
  name: 'ChainvaultmineTransferView',
  components: {
    Header, Sidebar, VuePagination
  },
  data() {
    return {
      showToast: false,
      message: '',
      minimumWithdraw: 4.58,
      withdrawsCount: 0,
      withdrawsAmount: 0,
      withdrawData: {
        data: [],
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 1
      },
      offset: 4,
      isSubmitting: false,
      inputAmount: 0,
    };
  },
  computed: {
      ...mapGetters({
          autenticado: 'user/autenticado',
          user: 'user/usuario',
          balance: 'user/balance',
          power: 'user/power',
          wallet: 'user/wallet'
      }),
      ...mapState(['selectedCurrency']),
      ...mapGetters(['convertToSelectedCurrency']),
  },
  created() {
    // Fetch balance solo si no está en Vuex
    this.$store.dispatch('user/fetchSaldo');
  },
  mounted() {
    this.getWithdrawsData()
    this.getWithdraws()
  },

  methods: {
    restrictToNumbersAndDecimal(event) {
        // Permitir solo números y un punto decimal
        const charCode = event.keyCode;
        const inputValue = event.target.value;
        // 46 es el código para el punto
        if ((charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) ||
            (inputValue.indexOf('.') !== -1 && charCode === 46)) {
            event.preventDefault();
        }
    },
    withdraw() {

        if (this.isSubmitting) return;

        // Inicia la animación del botón y deshabilítalo
        this.isSubmitting = true;

        


        // Verificar la conexión con el servidor
        if (!navigator.onLine) {
            // Mostrar un mensaje de error si no hay conexión a internet
            this.showToast = true;
            this.message = 'No internet connection available. Please try again later.';
            setTimeout(() => {
                this.showToast = false;
                this.message = '';
            }, 5000);
            this.isSubmitting = false;
            return;
        }
        // Validar el monto ingresado
        const usd = parseFloat(this.inputAmount);

        if(usd < this.minimumWithdraw){
          this.showToast = true;
          this.message = `Minimum withdrawal is ${this.minimumWithdraw} USD.`;
          setTimeout(() => {
                this.showToast = false;
                this.message = '';
            }, 5000);
          this.isSubmitting = false;
          return;
        }
        

        // Verificar si el valor ingresado es un número válido y mayor que cero
        if (isNaN(usd) || usd <= 0) {
            // Mostrar un mensaje de error o realizar alguna acción
            this.showToast = true;
            this.message = 'Please enter a valid amount greater than zero';
            setTimeout(() => {
                this.showToast = false;
                this.message = '';
            }, 5000);
            this.isSubmitting = false;
            return;
        }

        // Verificar si el monto a retirar es mayor que el saldo disponible
        if (usd > this.balance) {
            // Mostrar un mensaje de error si el monto es mayor que el saldo
            this.showToast = true;
            this.message = 'Insufficient balance';
            setTimeout(() => {
                this.showToast = false;
                this.message = '';
            }, 5000);
            return;
        }

        

        // Crear un objeto con los datos del retiro
        const withdrawForm = {
            usd: usd,
            bitcoinAddress: this.wallet
        };

        // Realizar la solicitud HTTP al backend para procesar el retiro
        this.isSubmitting = true; // Mostrar spinner de carga
        axios.post(process.env.VUE_APP_API_URL + 'withdraw', withdrawForm)
        .then(response => {
            // Manejar la respuesta del backend
            this.showToast = true;
            this.message = response.data.message;
            this.isSubmitting = false; // Ocultar spinner de carga
            // Actualizar información del usuario
            this.$store.dispatch('user/fetchSaldo');
            //this.bitcoinAddress = ''; // Limpiar el campo de dirección de Bitcoin
            this.getWithdraws();
            this.getWithdrawsData();
            setTimeout(() => {
                this.showToast = false;
                this.message = '';
            }, 5000);
        })
        .catch(error => {
            // Manejar errores de la solicitud
            if (error.response.status === 422) {
              if (typeof error.response.data.error === 'string') {
                this.message = error.response.data.error;
                this.showToast = true;
                this.isSubmitting = false;
                setTimeout(()=>{
                  this.showToast = false;
                  this.message = '';
                },5000);
              } 
            }
            
        });
    },
    formatDate(date) {
      return moment(date).format('MMM DD YYYY, h:mm A');
    },
    getWithdrawsData() {
      axios.get(process.env.VUE_APP_API_URL+'getWithdrawsData')
      .then((res)=>{
        this.withdrawsCount = res.data.withdrawsCount;
        this.withdrawsAmount = res.data.withdrawsAmount;
        
      })
      .catch((error)=>{
        console.log('error getting Data',error.response.error);
      })
    },
    getWithdraws() {
      axios.get(process.env.VUE_APP_API_URL+`getWithdraws?page=${this.withdrawData.current_page}`)
      .then((res)=>{
        this.withdrawData = res.data;
      })
      .catch((error)=>{
        console.log('error getting Data',error.response.error);
      })
    }
  },
};
</script>