<template>
  <div>
    <Header/>
    <main>
        <div class="bg-headline bg-no-repeat bg-cover bg-center relative">
            <div class="bg-gradient-to-tr from-black-95 via-black-75 to-black-95">
                <div class="2xl:container mx-auto px-4 py-14 lg:pl-28">
                    <h1 class="font-serif font-black text-4xl sm:text-5xl text-white">Pricing</h1>
                </div>
            </div>
        </div>

        <div class="2xl:container mx-auto px-2 pt-14 lg:pt-28">
            <div class="text-black text-lg font-medium leading-none text-center">You set the price. You have the control.</div>
            <h2 class="text-black font-serif font-black text-4xl leading-none mb-8 text-center">How much <span class="text-green">mining power</span> do you need?</h2>
            <div id="calculator" class="rounded-2xl bg-white shadow-xl p-6 md:p-9 mx-auto max-w-md lg:max-w-screen-lg grid grid-cols-1 lg:grid-cols-6 lg:bg-gift bg-right bg-cover sm:bg-contain bg-no-repeat">
                <div class="lg:col-span-2 lg:pr-6">
                    <div class="mb-2 font-medium text-center text-black">Amount you want to pay:</div>
                    <div class="flex items-center mb-4"><svg xmlns="http://www.w3.org/2000/svg" class="flex-none mr-4 text-green" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-4H8V8h3V6h2v2h1a2.5 2.5 0 0 1 2 4 2.5 2.5 0 0 1-2 4h-1v2h-2v-2zm-1-3v1h4a.5.5 0 1 0 0-1h-4zm0-3v1h4a.5.5 0 1 0 0-1h-4z"/></svg>
                        <input
                            class="text-black block border border-gray-light bg-white-dark rounded px-3 py-1.5 w-full placeholder-green price" type="number" v-model="inputAmount"
                            @input="updateCalculator"
                            @keypress="restrictToNumbers" 
                            id="btc" autocomplete="off">
                            <select id="currency" class="flex-none w-20 px-3 py-2 text-sm bg-white rounded-r-sm cursor-pointer setcurrency hover:bg-white-dark focus:bg-white-dark text-green-dark">
                                <option value="USD" selected>USD</option>
                            </select>
                        </div>
        
                    <div
                        class="grid items-center grid-cols-2 gap-4">
                        <div class="text-sm font-medium text-black uppercase"> Miner Hashrate: </div>
                        <div class="text-lg font-semibold text-right power text-green-dark">{{minerHashRate}} {{hashUnit}}</div>
                </div>
                <div class="grid items-center grid-cols-2 gap-4 pb-4 mb-4 border-b border-gray-light">
                    <div class="text-xs uppercase text-gray"> Unit Price: </div>
                    <div class="text-xs text-right unitprice text-green-dark">{{unitPrice}} USD</div>
                </div>
                <div class="grid grid-cols-2 gap-4 items-center mb-0.5">
                    <div class="text-sm font-medium uppercase text-gray"> Minimum: </div>
                    <div class="font-semibold text-right text-green">{{minimumHash.toFixed(2)}} TH/s</div>
                </div>
                <div class="grid items-center grid-cols-2 gap-4">
                    <div class="text-sm font-medium uppercase text-gray"> Maximum: </div>
                    <div class="font-semibold text-right text-green">{{maximumHash.toFixed(2)}} PH/s</div>
                </div>
            </div>
            <div class="lg:col-span-3 lg:pl-6 lg:border-l lg:border-gray-light">
                <div class="mb-6 font-medium text-black">Estimated Income by Hashrate</div>
                <div class="grid items-center grid-cols-3 gap-2 mb-1">
                    <div class="text-sm font-medium text-black uppercase"> per Hour: </div>
                    <div class="text-green hourlypc">{{ (hourlyIncome * 100).toFixed(2) }} %</div>
                    <div class="text-sm font-semibold lg:text-base text-green-dark hourly">{{ hourlyIncomeValue }} USD</div>
                </div>
                <div class="grid items-center grid-cols-3 gap-2 mb-1">
                    <div class="text-sm font-medium text-black uppercase"> per Day: </div>
                    <div class="text-green dailypc">{{ (dailyIncome * 100).toFixed(2) }} %</div>
                    <div class="text-sm font-semibold lg:text-base text-green-dark daily">{{ dailyIncomeValue }} USD</div>
                </div>
                <div class="grid items-center grid-cols-3 gap-2 mb-1">
                    <div class="text-sm font-medium text-black uppercase"> per Week: </div>
                    <div class="text-green weeklypc">{{ (weeklyIncome * 100).toFixed(2) }} %</div>
                    <div class="text-sm font-semibold lg:text-base text-green-dark weekly">{{ weeklyIncomeValue }} USD</div>
                </div>
                <div class="grid items-center grid-cols-3 gap-2 mb-4 border-b border-gray-light">
                    <div class="text-sm font-medium text-black uppercase"> per Month: </div>
                    <div class="text-green monthlypc">{{ (monthlyIncome * 100).toFixed(2) }} %</div>
                    <div class="text-sm font-semibold lg:text-base text-green-dark monthly">{{ monthlyIncomeValue }} USD</div>
                </div>
                <div class="grid items-center grid-cols-3 gap-2">
                    <div class="text-sm font-medium uppercase text-gray"> estimated ROI: </div>
                    <div></div>
                    <div class="font-semibold text-green">~ <span class="text-green-dark">{{ roi === '365' ? '∞' : roi +' '+ 'days' }}</span></div>
                </div>
            </div>
        </div>
        <p class="text-gray italic text-sm mt-6 text-center mx-auto max-w-xl"> Don't limit yourself to fixed price plans. You decide how much hardware power you want to buy! No maintenance or waiting periods. No hidden setup or transfer fees. No term limits. Your cloud mining hardware starts automatically and is always online
            to mine Bitcoins for you.</p>
        </div>
        <div class="2xl:container mx-auto px-2 pt-14 lg:pt-28 grid grid-cols-1 xl:grid-cols-2 gap-10 xl:gap-20">
            <div class="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
                <div class="bg-white rounded-2xl h-64 shadow-xl p-10 flex items-center justify-center"><img src="@/assets/images/m30s.jpg" width="auto" height="auto" alt="Whatsminer 30S"></div>
                <div class="bg-white rounded-2xl h-64 shadow-xl p-10 flex items-center justify-center"><img src="@/assets/images/ams17.jpg" width="auto" height="auto" alt="Antminer S17"></div>
                <div class="bg-white rounded-2xl h-64 shadow-xl p-10 flex items-center justify-center"><img src="@/assets/images/m31s.jpg" width="auto" height="auto" alt="Whatsminer 31S"></div>
            </div>
            <div class="flex flex-col items-center xl:items-start order-first xl:order-none">
                <div class="text-black text-lg font-medium leading-none">Bitcoin cloud mining perfected</div>
                <h2 class="text-black font-serif font-black text-4xl leading-none mt-1 mb-4">Mine <span class="text-green">your</span> own Bitcoins today</h2>
                <ul class="mt-4 leading-relaxed text-lg font-medium text-gray">
                    <li class="block"><svg xmlns="http://www.w3.org/2000/svg" class="text-green inline-block mr-2" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" /></svg>                        Top hardware from <strong>Bitmain</strong> &amp; <strong>Whatsminer</strong></li>
                    <li class="block"><svg xmlns="http://www.w3.org/2000/svg" class="text-green inline-block mr-2" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" /></svg><strong>No</strong>                        setup fees. <strong>No</strong> maintenance. <strong>No</strong> limits. </li>
                    <li class="block"><svg xmlns="http://www.w3.org/2000/svg" class="text-green inline-block mr-2" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" /></svg>                        Up to <strong>10% affiliate</strong> commission </li>
                    <li class="block"><svg xmlns="http://www.w3.org/2000/svg" class="text-green inline-block mr-2" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" /></svg><strong>1 TH/s</strong>                        sign up bonus. Up to <strong>12% daily</strong> earnings. </li>
                </ul>
            </div>
        </div>
        <div class="2xl:container mx-auto px-2 py-14 lg:py-28">
            <div class="rounded-2xl bg-white shadow-xl px-10 md:px-14 py-8 md:py-12 mx-auto max-w-screen-lg grid grid-cols-1 md:grid-cols-3 gap-4 sm:bg-gift bg-right bg-cover sm:bg-contain bg-no-repeat">
                <div class="md:col-span-2">
                    <div class="text-black text-lg font-medium leading-none">Limited Winter Special Gift</div>
                    <h2 class="text-black font-serif font-black text-4xl leading-none mt-1 mb-3">Free <span class="text-green font-sans">1000 GH/s</span> Miner Power</h2>
                    <p class="leading-relaxed text-gray">Sign up today and get <strong>1000 GH/s = 1 TH/s</strong> miner power for your cloud hardware immediately and without obligation!</p>
                </div>
                <div class="flex md:justify-center items-center"><router-link to="/register" class="shadow text-lg bg-green text-black hover:bg-green-dark rounded py-2 px-6 inline-flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="mr-2" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm7 8H7v2h4v4h2v-4h4v-2h-4V7h-2v4z" /></svg> Sign Up Now </router-link></div>
            </div>
            <div class="grid grid-cols-4 max-w-screen-lg items-center mt-8 mx-auto text-center gap-4">
                <div><img class="inline-block mx-auto" src="@/assets/images/ssl.png" width="auto" height="56" alt="SSL Certified">
                    <div class="text-gray text-xs mt-4">SSL Certified Platform</div>
                </div>
                <div><img class="inline-block mx-auto" src="@/assets/images/ukgov.png" width="100" height="auto" alt="UK Government">
                    <div class="text-gray text-xs mt-4">UK Approved Company</div>
                </div>
                <div><img class="inline-block mx-auto" src="@/assets/images/bca.png" width="100" height="auto" alt="Blockchain Association">
                    <div class="text-gray text-xs mt-4">Member of the<br>Blockchain Association</div>
                </div>
                <div><img class="inline-block mx-auto" src="@/assets/images/tfc.png" width="100" height="100" alt="The Financial Commission">
                    <div class="text-gray text-xs mt-4">Associated with the Financial Commission</div>
                </div>
            </div>
        </div>

      </main>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'ChainvaultminePricing',
  components: {
    Header, Footer
  },
  data() {
    return {
        hashUnit: 'TH/s',
        inputAmount: 18.78,
        unitPrice: 4.58,
        minimumHash: 4.10,
        maximumHash: 46.90,
        roi: 0,
        minerHashRate: 0,
        hourlyIncome: 0,
        hourlyIncomeValue: 0,
        dailyIncome: 0,
        dailyIncomeValue: 0,
        weeklyIncome: 0,
        weeklyIncomeValue: 0,
        monthlyIncome: 0,
        monthlyIncomeValue: 0,
    };
  },

  mounted() {
    this.updateCalculator();
  },

  methods: {
    restrictToNumbers(event) {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        // Permitir solo números y el punto decimal
        if (!/^[0-9.]+$/.test(keyValue)) {
            event.preventDefault();
        }

        // Asegurarse de que solo se permita un punto decimal
        if (keyValue === '.' && event.target.value.includes('.')) {
            event.preventDefault();
        }
    },
    convertHashRate(value, fromUnit, toUnit) {
        const units = {
            GH: 1,
            TH: 1000,
            PH: 1000000,
            EH: 1000000000
        };

        const fromValue = value * units[fromUnit];
        const toValue = fromValue / units[toUnit];

        return toValue;
    },
    convertToTHS(amount) {
        // Convertir a TH/s
        // Puedes ajustar esta función según las unidades que uses
        // GH/s, TH/s, PH/s, EH/s
        // Por ejemplo, si inputAmount es en PH/s, necesitas multiplicar por 1000 para obtener TH/s
        const hashUnits = ['GH/s', 'TH/s', 'PH/s', 'EH/s']; // Unidades de hash
        let index = 1; // Índice de TH/s por defecto
        
        while (amount >= 1000 && index < hashUnits.length - 1) {
            amount /= 1000;
            index++;
        }
        
        return {
            hashValue: amount,
            hashUnit: hashUnits[index]
        };
    },
    updateCalculator() {
        const inputAmount = this.inputAmount; // Valor por defecto
        const unitPrice = 4.58; // Valor por defecto
        
        let hashRate = 0;
        let monthly = 0;
        let daily = 0;
        let weekly = 0;
        let hourly = 0;
        let roi = 365;
        
        if(inputAmount > 0){
           // monthly = 2.22;
            if (inputAmount <= 30) {
                monthly = 1.22;
            } else if (inputAmount >= 31 && inputAmount <= 50) {
                monthly = 1.63;
            } else if (inputAmount >= 51 && inputAmount <= 120) {
                monthly = 1.83;
            } else if (inputAmount >= 121 && inputAmount <= 700) {
                monthly = 1.92;
            } else if (inputAmount >= 701 && inputAmount <= 1500) {
                monthly = 2.22;
            } else {
                // En este caso, inputAmount es mayor que 1500, pero puedes establecer el valor que desees
                // Si deseas mantener el valor de monthly en 2.22 para valores mayores que 1500, puedes dejar esta parte sin cambios
                monthly = 2.22;
            }
            
            daily = monthly / 30;
            weekly = daily * 7;
            hourly = daily / 24;
            
            roi = 30 / monthly;
        }
        hashRate = (inputAmount / unitPrice).toFixed(2);
        //hashRate = (unitPrice * inputAmount).toFixed(2);
        
        

        // Convertir el valor ingresado a TH/s
        let { hashValue, hashUnit } = this.convertToTHS(hashRate);

        //console.log(hashValue);
        this.minerHashRate = hashValue;
        this.hashUnit = hashUnit; // Unidad de hash
        
        // Ajustar los valores de los beneficios
        this.hourlyIncome = hourly;
        this.hourlyIncomeValue = (hourly * inputAmount).toFixed(2);
        this.dailyIncome = daily.toFixed(2);
        this.dailyIncomeValue = (daily * inputAmount).toFixed(2);
        this.weeklyIncome = weekly.toFixed(2);
        this.weeklyIncomeValue = (weekly * inputAmount).toFixed(2);
        this.monthlyIncome = monthly.toFixed(2);
        this.monthlyIncomeValue = (monthly * inputAmount).toFixed(2);
        
        this.roi = roi % 1 === 0 ? roi.toFixed(0) : roi.toFixed(1);
        
        
    },
    profitPerTime(amount) {
        let monthly = amount < 100 ? 1.2 : 1.93;
        let daily = monthly / 30;
        let weekly = daily * 7;
        let hourly = daily / 60;

        return { hourly, daily, weekly, monthly };  
    }
  },
};
</script>