<template>
  <div id="app">
    <keep-alive v-if="!isSpecificRoute">
      <BitcoinPrice key="bitcoin-price" />
    </keep-alive>
    <div 
        v-if="showToast"
        class="p-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 notification" role="alert">
          {{message}}
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import BitcoinPrice from './components/BitcoinPrices.vue';
import { mapGetters } from 'vuex';
export default {
  components: { BitcoinPrice },
  data() {
    return {
      showToast: false,
      message: ''
    };
  },
  computed: {
      isSpecificRoute() {
          const specificRoutes = ['/two-factor-auth', '/extra/rewards', '/extra/lucky', '/extra/mining','/eddie/admin'];
          return specificRoutes.includes(this.$route.path);
          //return this.$route.path === this.specificRoute;
      },
      ...mapGetters({
          autenticado: 'user/autenticado',
          user: 'user/usuario'
      }),
  },
  mounted() {
    //this.loadMode()
    //console.log(this.$route.path)
    if (this.autenticado) {
      this.listenForDeposits();
    }
  },
  methods: {
    listenForDeposits() {
      window.Echo.private(`deposits.${this.user.user.id}`)
        .listen('DepositReceived', (data) => {
      //    this.deposits.push(data);
          this.notifyUser(data);
        });
    },
    notifyUser(data) {
      // Aquí puedes implementar la lógica para notificar al usuario,
      // como mostrar un mensaje en pantalla.
      this.showToast = true;
      this.message = `Deposit received: ${data.amountUSD.toFixed(2)} USD, Power Updated: ${data.hashRate} TH/s`;
      setTimeout(()=>{
        this.showToast = false;
        this.message = '';
      },5000);
      
    },
  }
}
</script>
<style>
nav a.router-link-exact-active {
  color: #000;
  font-weight: 600
}

a.router-link-exact-active {
  color: #000;
  font-weight: 600
}

a.no-active-style.router-link-exact-active {
  color: #fff;
  font-weight: inherit;
}

a.no-active-style2.router-link-exact-active {
  color: inherit;
  font-weight: inherit;
}
</style>
