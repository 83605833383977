<template>
  <div>
    <Header/>
    <main class="min-h-screen" v-if="!hasReferralCode">
      <div class="relative bg-center bg-no-repeat bg-cover bg-hero">
            <div class="h-auto bg-gradient-to-tr from-black-95 via-black-75 to-black-95 min-h-104 lg:h-128">
                <div class="h-full p-4 pb-12 mx-auto 2xl:container sm:pb-0 lg:grid lg:grid-cols-4 lg:items-center"><svg xmlns="http://www.w3.org/2000/svg" class="w-32 h-32 mx-auto mb-8 lg:mb-0 lg:mx-0 lg:w-auto lg:h-auto text-green" viewBox="0 0 24 24" width="208" height="208"><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-4H8V8h3V6h2v2h1a2.5 2.5 0 0 1 2 4 2.5 2.5 0 0 1-2 4h-1v2h-2v-2zm-1-3v1h4a.5.5 0 1 0 0-1h-4zm0-3v1h4a.5.5 0 1 0 0-1h-4z" /></svg>
                    <div
                        id="slide1" class="col-span-2 mx-auto leading-none text-center text-white fadein">
                        <p class="text-xl font-medium">Simple. Secure. Super Fast.</p>
                        <h1 class="mt-2 mb-4 font-serif text-4xl font-black sm:text-5xl xl:text-6xl lg:mt-4 lg:mb-6">Mine <span class="text-green">Your</span> Bitcoins</h1>
                        <p class="text-xl font-medium">Join <span class="text-yellow">{{userCount}}</span> customers and start mining today</p>
                </div>
                <div id="slide2" class="col-span-2 mx-auto leading-none text-center text-white fadein" hidden>
                    <p class="text-xl font-medium">Free sign up bonus</p>
                    <h1 class="mt-2 mb-4 font-serif text-4xl font-black sm:text-5xl xl:text-6xl lg:mt-4 lg:mb-6">Free <span class="font-sans text-green">1 TH/s</span> miner power</h1>
                    <p class="text-xl font-medium">Sign up today and get <span class="text-yellow">1000 GH/s</span> for free</p>
                </div>
                <div id="slide3" class="col-span-2 mx-auto leading-none text-center text-white fadein" hidden>
                    <p class="text-xl font-medium">Mining experience without limits</p>
                    <h1 class="mt-2 mb-4 font-serif text-4xl font-black sm:text-5xl xl:text-6xl lg:mt-4 lg:mb-6">Up to <span class="font-sans text-green">12%</span> daily earnings</h1>
                    <p class="text-xl font-medium">Mine Bitcoins without <span class="text-yellow">fees</span> or <span class="text-yellow">term limits</span></p>
                </div>
                <div id="slidebtns" class="flex justify-center mt-8 lg:mt-0 lg:ml-auto lg:flex-col">
                    <div class="w-5 h-5 m-2 rounded bg-green"></div>
                    <div class="w-5 h-5 m-2 border-2 border-white rounded bg-none"></div>
                    <div class="w-5 h-5 m-2 border-2 border-white rounded bg-none"></div>
                </div>
            </div>
        </div>
        </div>

        <div class="flex items-center justify-center px-2 mx-auto transform -translate-y-1/2 2xl:container"><router-link to="/login" class="inline-flex items-center justify-center px-6 py-2 text-lg text-white bg-black rounded shadow w-44 hover:bg-black-dark"><svg xmlns="http://www.w3.org/2000/svg" class="mr-2 text-yellow" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M4 15h2v5h12V4H6v5H4V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-6zm6-4V8l5 4-5 4v-3H2v-2h8z" /></svg> Login </router-link>
            <router-link
                to="/register" class="inline-flex items-center justify-center p-2 ml-2.5 text-lg text-black rounded shadow sm:ml-6 w-44 bg-green hover:bg-green-dark"><svg xmlns="http://www.w3.org/2000/svg" class="mr-2" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm7 8H7v2h4v4h2v-4h4v-2h-4V7h-2v4z" /></svg>                Start Now </router-link>
        </div>

        <div class="px-2 pt-10 mx-auto 2xl:container lg:pt-20">
            <div class="grid max-w-sm grid-rows-3 mx-auto md:max-w-none md:grid-rows-none md:grid-cols-2 lg:grid-cols-3 gap-7 2xl:gap-28">
                <div class="text-center"><svg xmlns="http://www.w3.org/2000/svg" class="inline-block text-black" viewBox="0 0 24 24" width="64" height="64"><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-6v2h2v-2h1a2.5 2.5 0 0 0 2-4 2.5 2.5 0 0 0-2-4h-1V6h-2v2H8v8h3zm-1-3h4a.5.5 0 1 1 0 1h-4v-1zm0-3h4a.5.5 0 1 1 0 1h-4v-1z" /></svg>
                    <h2
                        class="my-3 font-serif text-2xl font-black text-black"><span class="text-green">Your</span> freedom to mine <span class="text-green">your</span> Bitcoins</h2>
                        <p class="leading-relaxed text-gray"> Bitcoin Cloud Mining is an easy way to mine bitcoins without buying them. You get the power of online mining without the need for expensive hardware. You can set your own prices and customize your mining process on your own terms.
                            </p>
                </div>
                <div class="text-center"><svg xmlns="http://www.w3.org/2000/svg" class="inline-block text-black" viewBox="0 0 24 24" width="64" height="64"><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M15.224 15.508l-2.213 4.65a.6.6 0 0 1-.977.155l-3.542-3.739a.6.6 0 0 0-.357-.182l-5.107-.668a.6.6 0 0 1-.449-.881l2.462-4.524a.6.6 0 0 0 .062-.396L4.16 4.86a.6.6 0 0 1 .7-.7l5.063.943a.6.6 0 0 0 .396-.062l4.524-2.462a.6.6 0 0 1 .881.45l.668 5.106a.6.6 0 0 0 .182.357l3.739 3.542a.6.6 0 0 1-.155.977l-4.65 2.213a.6.6 0 0 0-.284.284zm.797 1.927l1.414-1.414 4.243 4.242-1.415 1.415-4.242-4.243z" /></svg>
                    <h2
                        class="my-3 font-serif text-2xl font-black text-black"><span class="text-green">Easy</span> to start, <span class="text-green">no setup</span> required</h2>
                        <p class="leading-relaxed text-gray"> After successful registration and receiving our free bonus, our cloud miners will start directly and mine your Bitcoins, day and night - anytime without interruptions. </p>
                </div>
                <div class="text-center"><svg xmlns="http://www.w3.org/2000/svg" class="inline-block text-black" viewBox="0 0 24 24" width="64" height="64"><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M5.373 4.51A9.962 9.962 0 0 1 12 2c5.523 0 10 4.477 10 10a9.954 9.954 0 0 1-1.793 5.715L17.5 12H20A8 8 0 0 0 6.274 6.413l-.9-1.902zm13.254 14.98A9.962 9.962 0 0 1 12 22C6.477 22 2 17.523 2 12c0-2.125.663-4.095 1.793-5.715L6.5 12H4a8 8 0 0 0 13.726 5.587l.9 1.902zM8.5 14H14a.5.5 0 1 0 0-1h-4a2.5 2.5 0 1 1 0-5h1V7h2v1h2.5v2H10a.5.5 0 1 0 0 1h4a2.5 2.5 0 1 1 0 5h-1v1h-2v-1H8.5v-2z" /></svg>
                    <h2
                        class="my-3 font-serif text-2xl font-black text-black">Transfer <span class="text-green">your</span> earnings <span class="text-green">today</span></h2>
                        <p class="leading-relaxed text-gray"> Receive your Bitcoins directly into a Bitcoin wallet of your choice or exchange them and purchase even more hardware power, directly with one-click and no waiting time to increase your mining earnings even more! </p>
                </div>
            </div>
        </div>
        <div class="px-2 mx-auto 2xl:container pt-14 lg:pt-28">
            <div class="grid max-w-screen-lg grid-cols-1 gap-4 px-10 py-8 mx-auto bg-white bg-right bg-no-repeat bg-cover shadow-xl rounded-2xl md:px-14 md:py-12 md:grid-cols-3 sm:bg-gift sm:bg-contain">
                <div class="md:col-span-2">
                    
                    <div
                        class="text-lg font-medium leading-none text-black">Limited Special Gift</div>
                <h2 class="mt-1 mb-3 font-serif text-4xl font-black leading-none text-black">Free <span class="font-sans text-green">1000 GH/s</span> Miner Power</h2>
                <p class="leading-relaxed text-gray"> Sign up today and get <strong>1000 GH/s = 1 TH/s</strong> miner power for your cloud hardware immediately and without obligation! </p>
            </div>
            <div class="flex items-center md:justify-center"><router-link to="/register" class="inline-flex items-center justify-center px-6 py-2 text-lg text-black rounded shadow bg-green hover:bg-green-dark"><svg xmlns="http://www.w3.org/2000/svg" class="mr-2" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm7 8H7v2h4v4h2v-4h4v-2h-4V7h-2v4z" /></svg> Sign Up Now </router-link></div>
        </div>
        <div class="grid items-center max-w-screen-lg grid-cols-4 gap-4 mx-auto mt-8 text-center">
            <div><img class="inline-block mx-auto" src="@/assets/images/ssl.png" width="auto" height="56" alt="SSL Certified">
                <div class="mt-4 text-xs text-gray">SSL Certified Platform</div>
            </div>
            <div><img class="inline-block mx-auto" src="@/assets/images/ukgov.png" width="100" height="auto" alt="UK Government">
                <div class="mt-4 text-xs text-gray">UK Approved Company</div>
            </div>
            <div><img class="inline-block mx-auto" src="@/assets/images/bca.png" width="100" height="auto" alt="Blockchain Association">
                <div class="mt-4 text-xs text-gray">Member of the<br>Blockchain Association</div>
            </div>
            <div><img class="inline-block mx-auto" src="@/assets/images/tfc.png" width="100" height="100" alt="The Financial Commission">
                <div class="mt-4 text-xs text-gray">Associated with the Financial Commission</div>
            </div>
        </div>
        </div>

        
          <div class="grid items-center grid-cols-1 gap-10 px-2 mx-auto 2xl:container pt-14 lg:pt-28 xl:grid-cols-2">
              <div><img width="auto" height="auto" src="@/assets/images/dashboard.jpg" class="w-full max-w-xl mx-auto shadow-xl rounded-2xl" alt="Dashboard"></div>
              <div class="max-w-xl mx-auto xl:mx-0 xl:max-w-none">
                  <div class="text-lg font-medium leading-none text-black">Maximum control, just a click away</div>
                  <h2 class="mt-1 mb-4 font-serif text-4xl font-black leading-none text-black">Manage <span class="text-green">your</span> Cloud Miner</h2>
                  <p class="leading-relaxed text-gray">See live stats, manage and upgrade your Bitcoin Cloud miners at any time in your personal dashboard. Calculate future mining earnings in just a few clicks or recruit new customers and get additional hardware power through our <router-link class="font-bold text-green"
                          to="/affiliate">3-tier affiliate program</router-link>.</p>
                        
                          <router-link to="/insights" class="inline-flex items-center justify-center px-6 py-2 mt-6 text-lg text-white bg-black rounded shadow hover:bg-black-dark"><svg xmlns="http://www.w3.org/2000/svg" class="mr-2 text-yellow" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0L24 0 24 24 0 24z"></path><path fill="currentColor" d="M16 16c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zM6 12c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm10 6c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zM6 14c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zm8.5-12C17.538 2 20 4.462 20 7.5S17.538 13 14.5 13 9 10.538 9 7.5 11.462 2 14.5 2zm0 2C12.567 4 11 5.567 11 7.5s1.567 3.5 3.5 3.5S18 9.433 18 7.5 16.433 4 14.5 4z"></path></svg> Network Insights </router-link>
                        
                        </div>
          </div>
          <div class="grid grid-cols-1 gap-10 px-2 mx-auto 2xl:container py-14 lg:py-28 xl:grid-cols-2">
              <div class="flex flex-col items-center">
                  <div class="text-lg font-medium leading-none text-center text-black">Bitcoin cloud mining perfected</div>
                  <h2 class="mt-1 mb-4 font-serif text-4xl font-black leading-none text-center text-black">Mine <span class="text-green">your</span> own Bitcoins today</h2>
                  <ul class="mt-4 text-lg font-medium leading-relaxed text-gray">
                      <li class="block"><svg xmlns="http://www.w3.org/2000/svg" class="inline-block mr-2 text-green" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" /></svg>                        Top hardware from <strong>Bitmain</strong> &amp; <strong>Whatsminer</strong></li>
                      <li class="block"><svg xmlns="http://www.w3.org/2000/svg" class="inline-block mr-2 text-green" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" /></svg><strong>No</strong>                        setup fees. <strong>No</strong> maintenance. <strong>No</strong> limits. </li>
                      <li class="block"><svg xmlns="http://www.w3.org/2000/svg" class="inline-block mr-2 text-green" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" /></svg>                        Up to <strong>10% affiliate</strong> commission </li>
                      <li class="block"><svg xmlns="http://www.w3.org/2000/svg" class="inline-block mr-2 text-green" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" /></svg><strong>1 TH/s</strong>                        sign up bonus. Up to <strong>12% daily</strong> earnings. </li>
                  </ul><router-link to="/register" class="inline-flex items-center justify-center px-6 py-2 mt-6 text-lg text-black rounded shadow bg-green hover:bg-green-dark"><svg xmlns="http://www.w3.org/2000/svg" class="mr-2" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm7 8H7v2h4v4h2v-4h4v-2h-4V7h-2v4z" /></svg> Start Now </router-link></div>
              <div
                  class="grid grid-cols-1 gap-6 md:grid-cols-3 md:gap-8">
                  <div class="flex items-center justify-center h-64 p-10 bg-white shadow-xl rounded-2xl"><img src="@/assets/images/m30s.jpg" width="auto" height="auto" alt="Whatsminer 30S"></div>
                  <div class="flex items-center justify-center h-64 p-10 bg-white shadow-xl rounded-2xl"><img src="@/assets/images/ams17.jpg" width="auto" height="auto" alt="Antminer S17"></div>
                  <div class="flex items-center justify-center h-64 p-10 bg-white shadow-xl rounded-2xl"><img src="@/assets/images/m31s.jpg" width="auto" height="auto" alt="Whatsminer 31S"></div>
          </div>
          </div>

    </main>
    <main v-else>
  <div class="bg-headline bg-no-repeat bg-cover bg-center relative">
    <div class="bg-gradient-to-tr from-black-95 via-black-75 to-black-95">
      <div class="2xl:container mx-auto px-4 py-14 lg:pl-28">
        <h1 class="font-serif font-black text-4xl sm:text-5xl text-white">Sign up</h1>
      </div>
    </div>
  </div>
  <div v-if="showToast" class="p-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 notification" role="alert">
    <template v-if="errors">
      <ul>
        <template v-for="(fieldErrors, field) in errors">
          <li v-for="error in fieldErrors" :key="error">{{ field }}: {{ error }}</li>
        </template>
      </ul>
    </template>
    <template v-if="message !== ''">
      <p>{{message}}</p>
    </template>
  </div>
  <div class="2xl:container mx-auto px-2 py-14 lg:py-28">
    <form class="max-w-xs mx-auto" @submit.prevent="register">
      <div class="mb-6 flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-4" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"></path>
        </svg>
        <input class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full placeholder-green" type="text" v-model="formData.username" name="username" placeholder="New Username" spellcheck="false" autocomplete="username" autofocus="">
      </div>
      <div class="mb-6 flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-4" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M20 12a8 8 0 1 0-3.562 6.657l1.11 1.664A9.953 9.953 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10v1.5a3.5 3.5 0 0 1-6.396 1.966A5 5 0 1 1 15 8H17v5.5a1.5 1.5 0 0 0 3 0V12zm-8-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6z"></path>
        </svg>
        <input class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full placeholder-green" v-model="formData.email" type="email" name="email" placeholder="Your Email" spellcheck="false" autocomplete="email">
      </div>
      <div class="mb-6 flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-4" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M18 8h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h2V7a6 6 0 1 1 12 0v1zM5 10v10h14V10H5zm6 4h2v2h-2v-2zm-4 0h2v2H7v-2zm8 0h2v2h-2v-2zm1-6V7a4 4 0 1 0-8 0v1h8z"></path>
        </svg>
        <input class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full placeholder-green" v-model="formData.password" id="password" type="password" name="password" placeholder="New Password" spellcheck="false" autocomplete="new-password">
      </div>
      <div class="flex items-center">
        <button 
            :disabled="isSubmitting" 
            class="ml-auto bg-black text-white hover:bg-black-dark rounded py-1.5 px-5" 
            type="submit">
            <svg v-if="isSubmitting"
            class="loader animate-spin text-yellow on" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M12 2a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1zm0 15a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1zm8.66-10a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5A1 1 0 0 1 20.66 7zM7.67 14.5a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5a1 1 0 0 1 1.366.366zM20.66 17a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 0 1 1-1.732l2.598 1.5A1 1 0 0 1 20.66 17zM7.67 9.5a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 1 1 1-1.732l2.598 1.5A1 1 0 0 1 7.67 9.5z"></path></svg>
            <div class="inline-flex items-center justify-center" v-if="!isSubmitting"> 
              Continue 
              <svg xmlns="http://www.w3.org/2000/svg" class="text-yellow ml-1" viewBox="0 0 24 24" width="22" height="22">
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path fill="currentColor" d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"></path>
              </svg>
            </div>
          </button>
      </div>
      <p class="text-center mt-6 text-xs text-gray"> By clicking Sign Up, you are indicating that you have read and acknowledge our <router-link class="text-green" to="/terms">Terms of Service</router-link>. </p>
      
    </form>
  </div>
  
</main>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import axios from 'axios'
export default {
  name: 'HomeView',
  components: {
    Header, Footer
  },
  data() {
    return {
      currentSlide: 0,
      userCount: 0,
      numberOfCustomers: 392384, // Número dinámico
      slides: [
        {
          subtitle: 'Simple. Secure. Super Fast.',
          title: 'Mine Your Bitcoins',
          description: `Join ${this.numberOfCustomers} customers and start mining today`, // Utiliza el número dinámico
        },
        {
          subtitle: 'Free sign up bonus',
          title: 'Free 1 TH/s miner power',
          description: 'Sign up today and get 1000 GH/s for free',
        },
        {
          subtitle: 'Mining experience without limits',
          title: 'Up to 12% daily earnings',
          description: 'Mine Bitcoins without fees or term limits',
        },
      ],
      interval: null,
      formData: {
          password: '',
          email: '',
          username: '',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          ipAddress: null
      },
      isSubmitting: false,
      message: '',
      showToast: false,
      showError: false,
      errors: {},
    };
  },

  mounted() {
    if (this.hasReferralCode) {
      this.getIpaddress();
    } else {
      this.slider()
      this.conteoUsuarios()
    }
    
    
  },
  beforeMount() {
    this.interval = setInterval(this.conteoUsuarios, 60000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    hasReferralCode() {
      return !!this.$route.query.ref;
    }
  },
  methods: {
    register(){
      

      this.isSubmitting = true;
      this.errors = {};

      axios.post(process.env.VUE_APP_API_URL + 'register', {
        email: this.formData.email,
        username: this.formData.username,
        password: this.formData.password,
        timezone: this.formData.timezone,
        ipAddress: this.formData.ipAddress,
        referralCode: this.$route.query.ref
      })
      .then(response => {
          // Limpia los campos después de enviar
          //console.log(response.data.success)
          this.formData.email = '';
          this.formData.username = '';
          this.formData.password = '';
          this.showToast = true;
          this.message = response.data.success;
            
          // Si la solicitud fue exitosa, puedes redirigir al usuario a la página de inicio de sesión
          setTimeout(()=>{
              this.showToast = false;
              this.message = '';
              this.$router.push('/login');
            },5000);
            this.isSubmitting = false;
        })
      .catch(error => {
        // Si la solicitud falla, maneja los errores
        //console.log(error.response,'error')
        if (error.response.status === 422) {
          if (typeof error.response.data.error === 'string') {
            this.message = error.response.data.error;
            this.showToast = true;
            this.isSubmitting = false;

            setTimeout(()=>{
              this.showToast = false;
              this.message = '';
            },5000);
            
          } else {
            this.errors = error.response.data.error;
            this.showToast = true;
            this.isSubmitting = false;
            setTimeout(()=>{
              this.showToast = false;
              this.message = '';
              this.errors = {};
            },5000);
          }
          
        } else {
          // Maneja otros tipos de errores
          console.error(error);
        }
      });
    },
    getIpaddress(){
      axios.get('https://ipinfo.io')
      .then((res)=>{
        this.formData.ipAddress = res.data.ip;
        //console.log(res.data)
      })
      .catch((error)=>{
        console.log(error)
      })
    },
    conteoUsuarios() {
      axios.get(process.env.VUE_APP_API_URL+'usersCountData')
      .then((res)=>{
        const newUserCount = res.data;
        this.userCount = newUserCount + 20000;
      })
      .catch((error)=>{
        console.log('error getting insightsData',error.response.error);
      })
    },
    slider(){
      var slideButtons = document.getElementById('slidebtns').getElementsByTagName('div');
            var slides = [];
            slides[0] = document.getElementById('slide1');
            slides[1] = document.getElementById('slide2');
            slides[2] = document.getElementById('slide3');
            var slideNum = 0;
            setInterval(() => {
                slides[0].hidden = true;
                slides[1].hidden = true;
                slides[2].hidden = true;
                slideButtons[0].className = 'bg-none rounded border-2 border-white w-5 h-5 m-2';
                slideButtons[1].className = 'bg-none rounded border-2 border-white w-5 h-5 m-2';
                slideButtons[2].className = 'bg-none rounded border-2 border-white w-5 h-5 m-2';
                if (slideNum == 2) slideNum = 0;
                else slideNum++;
                slides[slideNum].hidden = false;
                slideButtons[slideNum].className = 'bg-green rounded w-5 h-5 m-2';
            }, 6500);
    },
    setSlide(index) {
      this.currentSlide = index;
    },
    buttonClass(index) {
      return this.currentSlide === index
        ? 'bg-green rounded w-5 h-5 m-2'
        : 'bg-none rounded border-2 border-white w-5 h-5 m-2';
    },
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    },
    updateNumberOfCustomers(newNumber) {
      this.numberOfCustomers = newNumber;
      this.slides[0].description = `Join ${this.numberOfCustomers} customers and start mining today`;
    }
  },
}
</script>
