<template>
  <div>
    <Header/>
    <main>
  <div class="bg-headline bg-no-repeat bg-cover bg-center relative">
    <div class="bg-gradient-to-tr from-black-95 via-black-75 to-black-95">
      <div class="2xl:container mx-auto px-4 py-14 lg:pl-28">
        <h1 class="font-serif font-black text-4xl sm:text-5xl text-white">Forgot password?</h1>
      </div>
    </div>
  </div>
  <div 
  v-if="showToast"
  class="p-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 notification" role="alert">
    {{message}}
  </div>
  <div class="2xl:container mx-auto px-2 py-14 lg:py-28">
    <form class="max-w-xs mx-auto" @submit.prevent="sendResetLink">
      <div class="mb-6 flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-4" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"></path>
        </svg>
        <input 
        v-model="formData.email"
        class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full placeholder-green" type="text" name="username" placeholder="Email" spellcheck="false" autocomplete="off" autofocus="">
      </div>
      <div class="flex items-center">
        <router-link class="mr-4 text-sm text-green hover:text-green-dark" to="/login">Sign in</router-link>
        <button 
        :disabled="isSubmitting" 
        class="ml-auto submit bg-black text-white hover:bg-black-dark rounded py-1.5 px-5" type="submit">
          <svg v-if="isSubmitting"
          class="loader animate-spin text-yellow on" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M12 2a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1zm0 15a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1zm8.66-10a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5A1 1 0 0 1 20.66 7zM7.67 14.5a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5a1 1 0 0 1 1.366.366zM20.66 17a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 0 1 1-1.732l2.598 1.5A1 1 0 0 1 20.66 17zM7.67 9.5a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 1 1 1-1.732l2.598 1.5A1 1 0 0 1 7.67 9.5z"></path>
          </svg>
          <div class="inline-flex items-center justify-center" v-if="!isSubmitting"> Continue <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 text-yellow" viewBox="0 0 24 24" width="22" height="22">
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path fill="currentColor" d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"></path>
            </svg>
          </div>
        </button>
      </div>
      
    </form>
  </div>
</main>
<Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import axios from 'axios'
export default {
  name: 'ChainvaultmineForgot',
  components: {
    Header, Footer
  },
  data() {
    return {
      isSubmitting: false,
      message: '',
      showToast: false,
      formData: {
          email: ''
      },
      country: '',
      ip: ''
    };
  },

  mounted() {
    this.getIpaddress();
    this.getUserLocation();
  },

  methods: {
    getIpaddress(){
      axios.get('https://ipinfo.io')
      .then((res)=>{
        //console.log(res.data);
        this.ip = res.data.ip;
      })
      .catch((error)=>{
        console.log(error)
      })
    },
    async getUserLocation() {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        //this.ip = data.ip;
        this.country = data.country_name;
      } catch (error) {
        console.error("Could not retrieve location data:", error);
      }
    },
    sendResetLink() {
      this.isSubmitting = true;
      this.showToast = false;
      

      axios.post(process.env.VUE_APP_API_URL + 'password/email', {
        email: this.formData.email,
        country: this.country,
        ip: this.ip
      })
      .then(response => {
        this.isSubmitting = false;
        this.showToast = true;
        this.message = response.data.message;
        this.formData.email = '';
        setTimeout(()=>{
              this.showToast = false;
              this.message = '';
            },5000);
        //window.scrollTo(0, 0); // Desplaza el scroll a la posición (0, 0)
      })
      .catch(error => {
        this.isSubmitting = false;
        this.showToast = true;
        if (error.response && error.response.data && error.response.data.email) {
          this.message = error.response.data.email[0];
        } else {
          this.message = error.response.data.error;
        }
        setTimeout(()=>{
              this.showToast = false;
              this.message = '';
            },5000);
       // window.scrollTo(0, 0); // Desplaza el scroll a la posición (0, 0)
      });
    },
  },
};
</script>